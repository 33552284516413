import {FormEventHandler, PropsWithChildren, SyntheticEvent, useEffect, useState} from 'react';
import {FormSection} from './FormSection';
import Button from '@mui/material/Button';
import {FormHelper, FormHelperProvider} from './FormHelper';
import {Grid} from '@mui/material';
import {FormActions} from './components/FormActions';
import {FormState} from './state/FormState';
import {OnEventHandler, OnRejectSubmitHandler, OnStateChangeHandler, OnSubmitHandler} from './model';
import {FormAPI} from './FormAPI';

export interface FormProps {
  helper: FormHelper;
  onSubmit: OnSubmitHandler;
  onRejectSubmit: OnRejectSubmitHandler;
  onStateChange?: OnStateChangeHandler;
  api?: FormAPI;
}

/**
 * Dumb form. This component wires the events between child components and FormStateHandler.
 */
export function Form(props: PropsWithChildren<FormProps>) {
  const {helper, onStateChange, onSubmit, onRejectSubmit, api, children} = props;
  const {stateHandler, schema, config} = helper;
  const {debug} = config;
  const [showDebug, setShowDebug] = useState(false);
  const [state, setState] = useState<FormState | undefined>(undefined);

  useEffect(() => {
    if (state && onStateChange) {
      onStateChange(state);
    }
  }, [state, onStateChange]);

  useEffect(() => {
    setState((prevState) => stateHandler.next());
  }, [stateHandler]);

  useEffect(() => {
    if (api) {
      const subscription = api.$events.subscribe((next) => {
        if (next.type === 'reset') {
          setState((prevState) => stateHandler.startFrom(prevState).reset().next());
        } else if (next.type === 'submit') {
          setState((prevState) => stateHandler.startFrom(prevState).submit(onSubmit, onRejectSubmit));
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [api, stateHandler, onSubmit, onRejectSubmit]);

  const handleSubmit: FormEventHandler = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setState((prevState) => stateHandler.startFrom(prevState).submit(onSubmit, onRejectSubmit));
  };

  const onChange: OnEventHandler = (event: any) => {
    console.debug('onChange', event);
    if (event && event.target) {
      const {name, value} = event.target;
      setState((prevState) => stateHandler.startFrom(prevState).change(name, value).next());
    }
  };

  const onBlur: OnEventHandler = (event: any) => {
    console.debug('onBlur', event);
    if (event && event.target) {
      const {name} = event.target;
      setState((prevState) => stateHandler.startFrom(prevState).blur(name).next());
    }
  };

  const onFocus: OnEventHandler = (event: any) => {
    console.debug('onFocus', event);
    if (event && event.target) {
      // TODO implement only if needed
    }
  };

  // TODO move this to textarea component
  const onKeyPress: OnEventHandler = (event: any) => {
    if (
      (event.code?.toLowerCase() === 'enter' || event.code?.toLowerCase() === 'numpadenter') &&
      event.target.localName !== 'textarea'
    ) {
      event.preventDefault();
    }
  };

  return state ? (
    <FormHelperProvider helper={helper}>
      <form onSubmit={handleSubmit} onKeyDown={(e) => onKeyPress(e)}>
        {schema.sections?.map((section, index, elements) => (
          <FormSection
            key={index}
            section={section}
            nextSection={elements[index + 1]}
            drillDownProps={{onChange, onBlur, onFocus, state}}
          />
        ))}
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <FormActions>
              {children}
              {debug && (
                <Button color={'secondary'} onClick={() => setShowDebug((prev) => !prev)}>
                  {showDebug ? 'Hide debug' : 'Show debug'}
                </Button>
              )}
            </FormActions>
          </Grid>
        </Grid>
        {debug && showDebug && (
          <div style={{fontSize: 'smaller'}}>
            <pre style={{backgroundColor: '#eee', borderRadius: '1em', padding: '1em', margin: '1em 0'}}>
              {JSON.stringify({...state, __helper: undefined}, null, 2)}
            </pre>
          </div>
        )}
      </form>
    </FormHelperProvider>
  ) : (
    <></>
  );
}
