import React, {useEffect, useState} from 'react';
import {CircularProgress, Grid, MenuItem, Select, TextField, Typography} from '@mui/material';
import {useSharedEntitiesContext} from '../contexts/SharedEntitiesContext';
import Checkbox from '@mui/material/Checkbox';
import {
  FormPermission,
  ResourcePermission,
  ResourcePermissionsBatchUpdate,
  ResourceType,
} from '../model/FormPermission';
import {ResourcePermissionsProvider, useResourcePermissionsContext} from '../contexts/ResourcePermissionsContext';
import {FormRole} from '../model/FormRole';
import {FormAction} from '../model/FormAction';

interface GroupedPermission {
  action_id: string;
  action_name: string;
  role_ids: string[];
}

export function compareByAction<T extends GroupedPermission>(a: T, b: T) {
  const nameA = a.action_name ?? '';
  const nameB = b.action_name ?? '';
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

function setPermRole(perm: GroupedPermission, roleId: string, checked: boolean): GroupedPermission {
  if (checked) {
    if (perm.role_ids.includes(roleId)) {
      return perm;
    } else {
      return {...perm, role_ids: [...perm.role_ids, roleId]};
    }
  } else {
    if (perm.role_ids.includes(roleId)) {
      return {...perm, role_ids: perm.role_ids.filter((rid) => rid !== roleId)};
    } else {
      return perm;
    }
  }
}

const alwaysToBeShownActionNames = ['view', 'modify'];

function addAlwaysToBeShownActionsWithNoSelectedRoles(allActions: FormAction[], perms: GroupedPermission[]) {
  const remoteActionIds = perms.map((a) => a.action_id);
  const alwaysToBeShownActions = allActions.filter((a) => alwaysToBeShownActionNames.includes(a.name));
  const notIncluded = alwaysToBeShownActions.filter((a) => !remoteActionIds.includes(a.action_id));
  const preselected: GroupedPermission[] =
    notIncluded.map((a) => ({action_id: a.action_id, action_name: a.name, role_ids: []})) ?? [];
  return [...preselected, ...perms];
}

const AvailableActionsForResourceType = {
  [ResourceType.MENU_ITEM]: ['view'],
  [ResourceType.SECTION]: ['view'],
  [ResourceType.FIELD]: ['view', 'modify'],
  [ResourceType.FIELD_VALUE]: ['view', 'modify'],
};

function isAvailableForResourceType(action: FormAction, resourceType: ResourceType): boolean {
  const available = AvailableActionsForResourceType[resourceType];
  if (available) {
    return available.includes(action.name);
  }
  return true;
}

interface Props {
  resourceId: string;
  resourceType: ResourceType;
}

export default function ResourcePermissionsComponent({resourceId, resourceType}: Props) {
  const {roles, actions} = useSharedEntitiesContext();
  const resourceActions = actions?.filter((a) => isAvailableForResourceType(a, resourceType)) ?? null;
  const activeRoles = roles?.filter((r) => !r.disabled) ?? null;
  return (
    <ResourcePermissionsProvider resourceId={resourceId}>
      <InternalResourcePermissionsComponent actions={resourceActions} roles={activeRoles} />
    </ResourcePermissionsProvider>
  );
}

interface InternalProps {
  actions: FormAction[] | null;
  roles: FormRole[] | null;
}

function InternalResourcePermissionsComponent({actions, roles}: InternalProps) {
  const {permissions, handleBatchUpdate} = useResourcePermissionsContext();
  const [groupedRemotePermissions, setGroupedRemotePermissions] = useState<GroupedPermission[]>([]);
  const [groupedLocalPermissions, setGroupedLocalPermissions] = useState<GroupedPermission[]>([]);
  const [batchUpdate, setBatchUpdate] = useState<ResourcePermissionsBatchUpdate>();
  const [actionIds, setActionIds] = useState<string[]>([]);
  // const [newAction, setNewAction] = useState<string>();
  const [newPerm, setNewPerm] = useState<GroupedPermission | null>(null);

  function toActionName(action_id?: string): string {
    return actions?.find((r) => r.action_id === action_id)?.name ?? '';
  }

  function buildGroupedPermissions(permissions: FormPermission[]): GroupedPermission[] {
    const tmp: {[keys: string]: string[]} = {};
    for (const el of permissions) {
      if (el.disabled) continue;
      const key = el.action_id;
      tmp[key] = tmp[key] ?? [];
      tmp[key].push(el.role_id);
    }
    return Object.entries(tmp).map(([action_id, role_ids]) => {
      return {action_id, action_name: toActionName(action_id), role_ids};
    });
  }

  useEffect(() => {
    if (actions && permissions) {
      setGroupedRemotePermissions(
        addAlwaysToBeShownActionsWithNoSelectedRoles(actions, buildGroupedPermissions(permissions))
      );
    } else {
      setGroupedRemotePermissions([]);
    }
  }, [permissions]);

  useEffect(() => {
    setGroupedLocalPermissions(groupedRemotePermissions);
  }, [groupedRemotePermissions]);

  useEffect(() => {
    setActionIds(groupedRemotePermissions.map((p) => p.action_id));
  }, [groupedRemotePermissions]);

  useEffect(() => {
    const toAdd: ResourcePermission[] = [];
    const toDelete: ResourcePermission[] = [];
    for (const local of groupedLocalPermissions) {
      const actionId = local.action_id;
      const remote = groupedRemotePermissions.find((p) => p.action_id === actionId);
      const remoteRoleIds = remote?.role_ids ?? [];
      for (const localRoleId of local.role_ids) {
        if (!remoteRoleIds.includes(localRoleId)) {
          toAdd.push({action_id: actionId, role_id: localRoleId});
        }
      }
      for (const removeRoleId of remoteRoleIds) {
        if (!local.role_ids.includes(removeRoleId)) {
          toDelete.push({action_id: actionId, role_id: removeRoleId});
        }
      }
    }
    if (toAdd.length || toDelete.length) {
      setBatchUpdate({toAdd, toDelete});
    }
  }, [groupedLocalPermissions]);

  useEffect(() => {
    if (!batchUpdate) return;
    const task = setTimeout(() => {
      handleBatchUpdate(batchUpdate);
    }, 750);
    return () => clearTimeout(task);
  }, [batchUpdate]);

  if (permissions === null || actions === null || roles === null) {
    return (
      <Grid container spacing={1} sx={{margin: 1}}>
        <Grid item>
          <CircularProgress size={20} />
        </Grid>
      </Grid>
    );
  }

  function updateActionRole(actionId: string, roleId: string, checked: boolean) {
    const index = groupedLocalPermissions.findIndex((p) => p.action_id === actionId);
    if (index > -1) {
      const local = groupedLocalPermissions[index];
      const newLocal = setPermRole(local, roleId, checked);
      const copy = [...groupedLocalPermissions];
      copy.splice(index, 1, newLocal);
      setGroupedLocalPermissions(copy);
    } else {
      const newLocal = {action_id: actionId, action_name: toActionName(actionId), role_ids: [roleId]};
      setGroupedLocalPermissions([...groupedLocalPermissions, newLocal]);
    }
    setNewPerm(null);
  }

  function setNewPermAction(action_id: string) {
    if (action_id && action_id.length) {
      setNewPerm({action_id, action_name: toActionName(action_id), role_ids: newPerm?.role_ids ?? []});
    }
  }

  function isLoading(actionId: string, roleId: string) {
    const remote = groupedRemotePermissions.find((p) => p.action_id === actionId);
    const local = groupedLocalPermissions.find((p) => p.action_id === actionId);
    return remote?.role_ids?.includes(roleId) !== local?.role_ids?.includes(roleId);
  }

  const missingActions = actions.filter((a) => !actionIds.includes(a.action_id));

  return (
    <>
      <Grid container spacing={1} key={'header'}>
        <Grid item xs={2}>
          <Typography>action</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            {roles.map((role, index) => (
              <Grid item xs={1}>
                <Typography>{role.role_name}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {groupedLocalPermissions.sort(compareByAction).map((p) => (
        <Grid container spacing={1} key={p.action_id}>
          <Grid item xs={2}>
            <TextField fullWidth disabled value={toActionName(p.action_id)}></TextField>
          </Grid>
          <Grid item xs={10}>
            <Grid container>
              {roles.map((role, index) => (
                <Grid item xs={1}>
                  {isLoading(p.action_id, role.role_id) ? (
                    <CircularProgress size={25} sx={{margin: 1}} />
                  ) : (
                    <Checkbox
                      checked={p.role_ids.includes(role.role_id)}
                      onChange={(event) => updateActionRole(p.action_id, role.role_id, event.target.checked)}
                    ></Checkbox>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ))}
      {missingActions.length > 0 && (
        <Grid container spacing={1} key={'new-line'}>
          <Grid item xs={2}>
            <Select
              fullWidth
              value={newPerm?.action_id ?? ''}
              onChange={(event) => setNewPermAction(event.target.value)}
            >
              <MenuItem key={''} value={''} disabled={true}>
                {'-'}
              </MenuItem>
              {missingActions.map((action) => (
                <MenuItem key={action.action_id} value={action.action_id}>
                  {action.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={10}>
            <Grid container>
              {newPerm &&
                roles.map((role, index) => (
                  <Grid item xs={1}>
                    {isLoading(newPerm.action_id, role.role_id) ? (
                      <CircularProgress size={25} sx={{margin: 1}} />
                    ) : (
                      <Checkbox
                        checked={newPerm.role_ids.includes(role.role_id)}
                        onChange={(event) => updateActionRole(newPerm.action_id, role.role_id, event.target.checked)}
                      ></Checkbox>
                    )}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
