import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, CircularProgress, Grid} from '@mui/material';
import {FormBuilder} from '../../../../../form/FormBuilder';
import {onRejectSubmit} from '../../../../../form/errorHandler';
import schema from './schema/menu_item.json';
import {FormSchema} from '../../../../../form/logic/FormSchema';
import {FormState} from '../../../../../form/state/FormState';
import {useNotifications} from '../../hooks/useNodifications';
import {FormMenuItem} from '../../model/FormMenuItem';
import ResourcePermissionsComponent from '../ResourcePermissionsComponent';
import ExpandableWrapper from '../shared/ExpandableWrapper';
import {useMenuItemsContext} from '../../contexts/MenuItemsContext';
import {difference} from '../../../../../form/utils';
import {ResourceType} from '../../model/FormPermission';

interface Props {
  menuItem: FormMenuItem;
}

export default function MenuItemEditorUpdate({menuItem}: Props) {
  const {t} = useTranslation();
  const {sendSuccess} = useNotifications();
  const {handlePatch, handleDelete, handleExport, handleCopy, locked} = useMenuItemsContext();

  function onSubmit(formData: any, state: FormState) {
    handlePatch(menuItem.menu_item_id, difference(formData, menuItem)).then((data) => sendSuccess(`MenuItem updated`));
  }

  function onDelete() {
    const ok = window.confirm('Are you sure?');
    if (!ok) return;
    handleDelete(menuItem.menu_item_id).then(() => sendSuccess(`MenuItem deleted`));
  }

  function onExport() {
    handleExport(menuItem.menu_item_id).then(() => sendSuccess(`Export completed`));
  }

  function onCopy() {
    handleCopy(menuItem.menu_item_id).then(() => sendSuccess(`Copy completed`));
  }

  return (
    <Grid container spacing={1} rowSpacing={5}>
      <Grid item xs={12}>
        <FormBuilder
          onRejectSubmit={onRejectSubmit}
          formSchema={schema as FormSchema}
          initialValues={menuItem}
          onSubmit={onSubmit}
        >
          <Button disabled={locked} type={'submit'}>
            {t('shared.update')}
          </Button>
          <Button disabled={locked} onClick={onDelete} color={'error'}>
            {t('shared.delete')}
          </Button>
          <Button disabled={locked} onClick={onExport} color={'secondary'}>
            {t('shared.export')}
          </Button>
          <Button disabled={locked} onClick={onCopy} color={'secondary'}>
            {t('shared.copy')}
          </Button>
          {locked && <CircularProgress size={30} sx={{marginLeft: 1}} />}
        </FormBuilder>
      </Grid>
      <Grid item xs={12}>
        <ExpandableWrapper label={'Permissions'}>
          <ResourcePermissionsComponent resourceId={menuItem.menu_item_id} resourceType={ResourceType.MENU_ITEM} />
        </ExpandableWrapper>
      </Grid>
    </Grid>
  );
}
