import {Team} from '../../model/Team';

export interface TeamUserEntity {
  user_id: string;
  username: string;
}

export interface TeamResponse {
  created_at: string;
  created_by: TeamUserEntity;
  description: string;
  last_updated_at: string;
  last_updated_by: TeamUserEntity;
  members: Array<TeamUserEntity>;
  name: string;
  status: string;
  team_id: string;
  campaign: Array<string>;
}

export function toTeam(resp: TeamResponse): Team {
  return {
    ...resp,
    campaigns: resp.campaign,
    members: resp.members?.map((member) => member.user_id),
  };
}
