import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Grid} from '@mui/material';
import {FormBuilder} from '../../../../../form/FormBuilder';
import {onRejectSubmit} from '../../../../../form/errorHandler';
import schema from './schema/field_value_update.json';
import {FormSchema} from '../../../../../form/logic/FormSchema';
import {useNotifications} from '../../hooks/useNodifications';
import {FormState} from '../../../../../form/state/FormState';
import ExpandableWrapper from '../shared/ExpandableWrapper';
import ResourcePermissionsComponent from '../ResourcePermissionsComponent';
import {FormFieldValue} from '../../model/FormFieldValue';
import {useFieldValuesContext} from '../../contexts/FieldValuesContext';
import {difference} from '../../../../../form/utils';
import {ResourceType} from '../../model/FormPermission';

interface Props {
  fieldValue: FormFieldValue;
}

export default function FieldValueEditorUpdate({fieldValue}: Props) {
  const {t} = useTranslation();
  const {sendSuccess} = useNotifications();
  const {handlePatch, handleDelete, locked} = useFieldValuesContext();

  function onSubmit(formData: any, state: FormState) {
    handlePatch(fieldValue.field_value_id, difference(formData, fieldValue)).then((data) => {
      sendSuccess(`FieldValue updated`);
    });
  }

  function onDelete() {
    const ok = window.confirm('Are you sure?');
    if (!ok) return;
    handleDelete(fieldValue.field_value_id).then(() => sendSuccess(`FieldValue deleted`));
  }

  return (
    <Grid container spacing={1} rowSpacing={5}>
      <Grid item xs={12}>
        <FormBuilder
          onRejectSubmit={onRejectSubmit}
          formSchema={schema as FormSchema}
          initialValues={fieldValue}
          onSubmit={onSubmit}
        >
          <Button disabled={locked} type={'submit'}>
            {t('shared.update')}
          </Button>
          <Button disabled={locked} onClick={onDelete} color={'error'}>
            {t('shared.delete')}
          </Button>
        </FormBuilder>
      </Grid>
      <Grid item xs={12}>
        <ExpandableWrapper label={'Permissions'}>
          <ResourcePermissionsComponent
            resourceId={fieldValue.field_value_id}
            resourceType={ResourceType.FIELD_VALUE}
          />
        </ExpandableWrapper>
      </Grid>
    </Grid>
  );
}
