import React, {useState} from 'react';
import {Button, CircularProgress} from '@mui/material';
import {useFetch} from '../hooks/useFetch';
import {useNotifications} from '../hooks/useNodifications';
import {FeatureName} from '../../../../../paths';
import {useConfig} from '../../../../context/ConfigContext';

interface Props {}

export default function ClearFormCacheButton({}: Props) {
  const {BFF_API_PATH} = useConfig();
  const {send} = useFetch();
  const {sendSuccess} = useNotifications();
  const [pending, setPending] = useState(false);

  function clearFormCache() {
    setPending(true);
    send('DELETE', `${BFF_API_PATH}/${FeatureName.FEATURE_FIELDS}/cache`)
      .then((data) => sendSuccess(data.message ?? 'success'))
      .finally(() => setPending(false));
  }

  return (
    <>
      <Button color="secondary" onClick={() => clearFormCache()} size={'medium'} disabled={pending}>
        {'clear form cache'} {pending && <CircularProgress size={25} sx={{marginLeft: 1}} />}
      </Button>
    </>
  );
}
