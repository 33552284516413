import {FormSchema} from '../../../../../form/logic/FormSchema';
import single_condition_equal from './templates/single_condition_equal.json';
import multiple_condition from './templates/multiple_condition.json';
import required_when_other_is_blank from './templates/required_when_other_is_blank.json';
import triggers_set_value from './templates/triggers_set_value.json';
import validations_strong_password from './templates/validations_strong_password.json';
import {FormFieldCondition} from '../../../../../form/logic/conditions/Condition';
import {FormFieldTriggerAction} from '../../../../../form/logic/triggers/Trigger';

const VALUES = {
  static: true,
  single_condition_equal,
  multiple_condition,
  required_when_other_is_blank,
  validations_strong_password,
  triggers_set_value,
};

export function appendRuleTemplates(schema: FormSchema): FormSchema {
  const section = schema.sections?.find((s) => s.section_id === 's3');
  if (!section) throw Error('section `s3` is missing');
  const rule = section.fields?.find((s) => s.field_id === 'rule');
  if (!rule) throw Error('field `rule` in section `s3` is missing');
  rule.triggers = [];
  for (const value in VALUES) {
    const action = {
      type: 'set_value',
      value: JSON.stringify(VALUES[value], null, 2),
    } as FormFieldTriggerAction;
    const condition = {
      type: 'single_condition',
      field_name: 'rule_templates',
      operator: 'equal',
      value: value,
    } as FormFieldCondition;
    rule.triggers.push({action, condition});
  }
  const rule_templates = section.fields?.find((s) => s.field_id === 'rule_templates');
  if (!rule_templates) throw Error('field `rule_templates` in section `s3` is missing');
  rule_templates.options = [];
  for (const value in VALUES) {
    rule_templates.options.push({label: value, value});
  }
  return schema;
}
