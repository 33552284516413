import {useEffect, useState} from 'react';
import UserService from '../../../services/UserService';
import {UserPermissions} from '../../../components/shared/AccessControl';
import {WebSocketCampaigns} from '../../../model/WebSocketCampaign';
import {WebSocketCampaignService} from '../../../services/WebSocketCampaignService';

interface UserCampaignsProps {
  userPermissions: UserPermissions[];
  isPowerUser: boolean;
}

// returns list of WebSocketCampaign(s) that current user is allowed to see
export function useUserCampaigns({userPermissions, isPowerUser}: UserCampaignsProps): WebSocketCampaigns | undefined {
  const [userCampaignIds, setUserCampaignIds] = useState<string[]>([]);
  const [wsCampaigns, setWsCampaigns] = useState<WebSocketCampaigns | undefined>();
  const [userCampaigns, setUserCampaigns] = useState<WebSocketCampaigns | undefined>();
  const [userOperationId, setUserOperationId] = useState<string>();

  // keeps updating campaign IDs assigned to current user
  useEffect(() => {
    const sub = UserService.getInstance()
      .get()
      .subscribe((_user) => {
        if (_user?.campaigns) {
          setUserCampaignIds(_user.campaigns.map((c) => c.campaign_id));
        }
        if (_user?.operation_id) {
          setUserOperationId(_user.operation_id);
        }
      });
    return () => sub.unsubscribe();
  }, []);

  // keeps updating campaigns coming from WS
  useEffect(() => {
    const sub = WebSocketCampaignService.getInstance()
      .listen()
      .subscribe((campaigns: WebSocketCampaigns) => setWsCampaigns(campaigns));
    return () => sub.unsubscribe();
  }, []);

  // if current user CAN_VIEW_ALL_CAMPAIGNS: returns all wsCampaigns
  // else: returns wsCampaigns with campaign ID assigned to current user
  useEffect(() => {
    if (wsCampaigns) {
      let _userCampaigns = {...wsCampaigns};
      const canViewAllCampaigns = userPermissions.includes(UserPermissions.VIEW_ALL_CAMPAIGNS);
      const shouldFilterCampaigns = (canViewAllCampaigns && userOperationId) || !canViewAllCampaigns;
      if (shouldFilterCampaigns) {
        for (const [campaign_type, campaigns] of Object.entries(_userCampaigns)) {
          let filteredCampaigns = campaigns;
          if (canViewAllCampaigns && userOperationId) {
            filteredCampaigns = campaigns.filter((c) => c.operation_id == userOperationId);
          } else if (!canViewAllCampaigns) {
            filteredCampaigns = campaigns.filter((c) =>
              userCampaignIds.find((campaign_id) => {
                const campaignMatch = campaign_id === c.campaign_id;
                if (userOperationId) {
                  return campaignMatch && c.operation_id == userOperationId;
                }
                return campaignMatch;
              })
            );
          }
          _userCampaigns[campaign_type] = filteredCampaigns;
        }
      }
      setUserCampaigns(_userCampaigns);
    }
  }, [userPermissions, userCampaignIds, wsCampaigns, isPowerUser, userOperationId]);

  return userCampaigns;
}
