import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress} from '@mui/material';
import {useFetch} from '../../../../system-settings/form-editor/hooks/useFetch';
import {FormBuilder} from '../../../../../form/FormBuilder';
import {onRejectSubmit} from '../../../../../form/errorHandler';
import {FormState} from '../../../../../form/state/FormState';
import {difference} from '../../../../../form/utils';
import {useNotifications} from '../../../../system-settings/form-editor/hooks/useNodifications';
import {useTranslation} from 'react-i18next';
import ModalWrapper from '../../../../system-settings/form-editor/components/shared/ModalWrapper';
import {CronJob, CronJobEditable, EMPTY_CJ, toCronJob, toCronJobEditable} from '../model';
import schema from './schema/cronjob.json';
import {FormSchema} from '../../../../../form/logic/FormSchema';
import {useConfig} from '../../../../../context/ConfigContext';

interface Props {
  cronjob: CronJob | null;
  onCreate: (namespace: string, cronjobName: string) => void;
  onDelete: (namespace: string, cronjobName: string) => void;
}

export default function CronJobEditor({cronjob, onCreate, onDelete}: Props) {
  const {t} = useTranslation();
  const {JOB_API_BASE_URL} = useConfig();
  const {send} = useFetch();
  const {sendSuccess, sendError} = useNotifications();
  const [valid, setValid] = useState(false);
  const [locked, setLocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shouldCreateNew, setShouldCreateNew] = useState(false);
  const [cronjobEditable, setCronjobEditable] = useState<CronJobEditable | null>(null);
  const [raw, setRaw] = useState<any>(null);

  useEffect(() => {
    setRaw(null);
    setCronjobEditable(null);
    setShouldCreateNew(cronjob === null);
    if (cronjob) {
      send('GET', `${JOB_API_BASE_URL}/cronjobs/${cronjob.cronjob_name}?namespace=${cronjob.namespace}`).then((data) =>
        setCronjobEditable(toCronJobEditable(data))
      );
    } else {
      setCronjobEditable(toCronJobEditable(EMPTY_CJ));
    }
  }, [cronjob]);

  function onSubmit(formData: any, state: FormState) {
    setLocked(true);
    const data = difference(toCronJob(formData), cronjob ?? {}) as Partial<CronJob>;
    if (cronjob === null || data.namespace || data.cronjob_name) {
      send('POST', `${JOB_API_BASE_URL}/cronjobs`, data)
        .then((data) => {
          onCreate(data.namespace, data.cronjob_name);
          setCronjobEditable(toCronJobEditable(data));
          sendSuccess(`CronJob created`);
        })
        .finally(() => setLocked(false));
    } else {
      send('PATCH', `${JOB_API_BASE_URL}/cronjobs/${cronjob.cronjob_name}?namespace=${cronjob.namespace}`, data)
        .then(() => sendSuccess(`CronJob updated`))
        .then(() => send('GET', `${JOB_API_BASE_URL}/cronjobs/${cronjob.cronjob_name}?namespace=${cronjob.namespace}`))
        .then((data) => setCronjobEditable(toCronJobEditable(data)))
        .finally(() => setLocked(false));
    }
  }

  function onStateChange(state: FormState) {
    if (cronjob) {
      setShouldCreateNew(
        state.values.cronjob_name !== cronjob.cronjob_name || state.values.namespace !== cronjob.namespace
      );
    }
  }

  function onDeleteHandler() {
    if (!cronjob) return;
    setLocked(true);
    send('DELETE', `${JOB_API_BASE_URL}/cronjobs/${cronjob.cronjob_name}?namespace=${cronjob.namespace}`)
      .then((data) => {
        onDelete(cronjob.namespace, cronjob.cronjob_name);
        sendSuccess(`CronJob deleted`);
      })
      .finally(() => setLocked(false));
  }

  function onShowRaw() {
    if (!cronjob) return;
    setLoading(true);
    send('GET', `${JOB_API_BASE_URL}/cronjobs/${cronjob.cronjob_name}?namespace=${cronjob.namespace}`)
      .then((data) => setRaw(data.raw))
      .finally(() => setLoading(false));
  }

  return cronjobEditable === null ? (
    <CircularProgress sx={{margin: 2, marginLeft: 2}} />
  ) : (
    <Box sx={{marginBottom: 5}}>
      <FormBuilder
        onRejectSubmit={onRejectSubmit}
        formSchema={schema as FormSchema}
        initialValues={cronjobEditable}
        onSubmit={onSubmit}
        onStateChange={onStateChange}
      >
        {shouldCreateNew ? (
          <>
            <Button disabled={locked} type={'submit'}>
              {t('shared.create')}
            </Button>
          </>
        ) : (
          <>
            <Button disabled={locked} type={'submit'}>
              {t('shared.update')}
            </Button>
            <Button disabled={locked} onClick={onDeleteHandler} color={'error'}>
              {t('shared.delete')}
            </Button>
            <Button disabled={loading} onClick={onShowRaw} color={'secondary'}>
              Show Raw {loading && <CircularProgress size={25} />}
            </Button>
          </>
        )}
      </FormBuilder>
      {raw && (
        <ModalWrapper open={true} handleClose={() => setRaw(null)}>
          <pre>{JSON.stringify(raw, null, 2)}</pre>
        </ModalWrapper>
      )}
    </Box>
  );
}
