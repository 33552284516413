import {sortByFuncBuilder} from './sortBy';

export interface CronjobEnvVars {
  name: string;
  value: string;
}

export interface CronJob {
  cronjob_name: string;
  namespace: string;
  image: string;
  schedule: string;
  suspend?: boolean | null;
  command?: string[];
  args?: string[];
  env_vars?: CronjobEnvVars[];
}

export const sortCronJobFunc = sortByFuncBuilder<CronJob>((cj) => cj.namespace + cj.cronjob_name);

export const EMPTY_CJ: CronJob = {
  cronjob_name: '',
  namespace: '',
  image: '',
  schedule: '',
};

export interface CronJobEditable {
  cronjob_name?: string;
  namespace?: string;
  image?: string;
  schedule?: string;
  suspend?: boolean | null;
  arg_0?: string;
  arg_1?: string;
  arg_2?: string;
  env_var_0_name?: string;
  env_var_0_value?: string;
  env_var_1_name?: string;
  env_var_1_value?: string;
  env_var_2_name?: string;
  env_var_2_value?: string;
}

export function toCronJobEditable(cronjob: CronJob): CronJobEditable {
  const args = cronjob.args ?? [];
  const command = cronjob.command ?? [];
  const env_vars = cronjob.env_vars ?? [];
  if (args.length > 3) throw Error(`this job cannot be visualized (too many args, found ${args.length}, max is 3)`);
  if (env_vars.length > 3)
    throw Error(`this job cannot be visualized (too many env_vars, found ${env_vars.length}, max is 3)`);
  if (command.length > 0) throw Error('this job cannot be visualized (command not empty)');
  return {
    ...cronjob,
    arg_0: args.find((v, i) => i === 0),
    arg_1: args.find((v, i) => i === 1),
    arg_2: args.find((v, i) => i === 2),
    env_var_0_name: env_vars.find((v, i) => i === 0)?.name,
    env_var_0_value: env_vars.find((v, i) => i === 0)?.value,
    env_var_1_name: env_vars.find((v, i) => i === 1)?.name,
    env_var_1_value: env_vars.find((v, i) => i === 1)?.value,
    env_var_2_name: env_vars.find((v, i) => i === 2)?.name,
    env_var_2_value: env_vars.find((v, i) => i === 2)?.value,
  };
}

export function validateCronJob(cronjob: CronJob): string | undefined {
  const args = cronjob.args ?? [];
  const command = cronjob.command ?? [];
  const env_vars = cronjob.env_vars ?? [];
  if (args.length > 3) {
    return `too many args (found ${args.length}, max is 3)`;
  } else if (env_vars.length > 3) {
    return `too many env_vars (found ${env_vars.length}, max is 3)`;
  } else if (command.length > 0) {
    return 'command not empty';
  }
}

export function toCronJob(ce: CronJobEditable): CronJob {
  const args: string[] = [];
  if (ce.arg_0) args.push(ce.arg_0);
  if (ce.arg_1) args.push(ce.arg_1);
  if (ce.arg_2) args.push(ce.arg_2);
  const env_vars: CronjobEnvVars[] = [];
  if (ce.env_var_0_name && ce.env_var_0_value) env_vars.push({name: ce.env_var_0_name, value: ce.env_var_0_value});
  if (ce.env_var_1_name && ce.env_var_1_value) env_vars.push({name: ce.env_var_1_name, value: ce.env_var_1_value});
  if (ce.env_var_2_name && ce.env_var_2_value) env_vars.push({name: ce.env_var_2_name, value: ce.env_var_2_value});
  return {
    cronjob_name: ce.cronjob_name ?? '',
    namespace: ce.namespace ?? '',
    image: ce.image ?? '',
    schedule: ce.schedule ?? '',
    suspend: ce.suspend,
    args: args,
    env_vars: env_vars,
  };
}
