import React from 'react';
import PageContainer from '../PageContainer';
import CronJobsComponent from './components/CronJobsComponent';

export default function CronJobsPage() {
  return (
    <PageContainer title={'CronJobs'}>
      <CronJobsComponent />
    </PageContainer>
  );
}
