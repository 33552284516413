import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Grid} from '@mui/material';
import {FormBuilder} from '../../../../../form/FormBuilder';
import {onRejectSubmit} from '../../../../../form/errorHandler';
import corule_create from './schema/corule_create.json';
import {FormSchema} from '../../../../../form/logic/FormSchema';
import {FormState} from '../../../../../form/state/FormState';
import {useNotifications} from '../../hooks/useNodifications';
import {FormCoRule} from '../../model/FormCoRule';
import {useCoRulesContext} from '../../contexts/CoRulesContext';
import {appendRuleTemplates} from './CoRuleTemplates';

interface Props {
  partial: Partial<FormCoRule>;
}

export default function CoRuleEditorCreate({partial}: Props) {
  const {t} = useTranslation();
  const [schema, _] = useState<FormSchema>(appendRuleTemplates(corule_create as FormSchema));
  const {sendSuccess} = useNotifications();
  const {handleCreate, locked} = useCoRulesContext();

  function onSubmit(formData: any, state: FormState) {
    delete formData['rule_templates'];
    handleCreate(formData).then(() => sendSuccess(`ConditionalRule created`));
  }

  return (
    <Grid container spacing={3} rowSpacing={3}>
      <Grid item xs={12}>
        <FormBuilder
          onRejectSubmit={onRejectSubmit}
          formSchema={schema as FormSchema}
          initialValues={partial}
          onSubmit={onSubmit}
        >
          <Button disabled={locked} type={'submit'}>
            {t('shared.save')}
          </Button>
        </FormBuilder>
      </Grid>
    </Grid>
  );
}
