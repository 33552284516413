import {FormBaseEntity} from '../model/FormBaseEntity';

export enum ResourceType {
  FEATURE = 'feature',
  SECTION = 'section',
  FIELD = 'field',
  FIELD_VALUE = 'field_value',
  MENU_ITEM = 'menu_item',
}

export interface FormPermission extends FormBaseEntity {
  permission_id: string;
  resource_id: string;
  resource_type: ResourceType;
  action_id: string;
  role_id: string;
  disabled: boolean;
}

export interface ResourcePermission {
  action_id: string;
  role_id: string;
}

export interface ResourcePermissionsBatchUpdate {
  toAdd: ResourcePermission[];
  toDelete: ResourcePermission[];
}
