import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Grid} from '@mui/material';
import {FormBuilder} from '../../../../../form/FormBuilder';
import {onRejectSubmit} from '../../../../../form/errorHandler';
import schema from './schema/field_create.json';
import {FormSchema} from '../../../../../form/logic/FormSchema';
import {FormField} from '../../model/FormField';
import {FormState} from '../../../../../form/state/FormState';
import {useNotifications} from '../../hooks/useNodifications';
import {useFieldsContext} from '../../contexts/FieldsContext';

interface Props {
  partial: Partial<FormField>;
}

export default function FieldEditorCreate({partial}: Props) {
  const {t} = useTranslation();
  const {sendSuccess} = useNotifications();
  const {handleCreate, locked} = useFieldsContext();

  function onSubmit(formData: any, state: FormState) {
    handleCreate(formData).then(() => sendSuccess(`Field created`));
  }

  return (
    <Grid container spacing={3} rowSpacing={3}>
      <Grid item xs={12}>
        <FormBuilder
          onRejectSubmit={onRejectSubmit}
          formSchema={schema as FormSchema}
          initialValues={partial}
          onSubmit={onSubmit}
        >
          <Button disabled={locked} type={'submit'}>
            {t('shared.save')}
          </Button>
        </FormBuilder>
      </Grid>
    </Grid>
  );
}
