import React, {ChangeEvent, useRef, useState} from 'react';
import {Button, CircularProgress, Grid, Typography} from '@mui/material';
import {useImportExport} from '../hooks/useImportExport';
import {useNotifications} from '../hooks/useNodifications';

interface Props {
  onImportCompleted?: () => void;
}

export default function ImportComponent({onImportCompleted}: Props) {
  const {importEntity, importing} = useImportExport();
  const ref = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const {sendSuccess} = useNotifications();

  async function onImport() {
    if (file) {
      const ok = window.confirm('Are you sure?');
      if (!ok) return;
      await importEntity(file);
      sendSuccess(`Import completed`);
      handleFileReset();
      onImportCompleted && onImportCompleted();
    }
  }

  async function onFileSelect() {
    if (ref.current) {
      ref.current.click();
    }
  }

  function handleFileReset() {
    setFile(null);
    if (ref.current) {
      ref.current.files = null;
    }
  }

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    setFile(e.target.files?.item(0) ?? null);
  }

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button disabled={!file || importing} onClick={onImport} color={'warning'} size={'medium'}>
          import
        </Button>
      </Grid>
      <Grid item>
        <Button disabled={importing} onClick={onFileSelect} color={'primary'} size={'medium'}>
          {file ? 'change file' : 'select file'}
        </Button>
      </Grid>
      <input ref={ref} value="" type="file" onChange={handleFileChange} style={{display: 'none'}} accept=".json" />
      {file && (
        <Grid item>
          <Typography variant={'body1'}>{file.name}</Typography>
        </Grid>
      )}
      {importing && (
        <Grid item>
          <CircularProgress size={28} />
        </Grid>
      )}
    </Grid>
  );
}
