import {Component, ErrorInfo, PropsWithChildren} from 'react';
import {Alert, Box, Typography} from '@mui/material';

function ErrorComponent({error}: {error: Error}) {
  return (
    <>
      <Box>
        <Alert severity={'warning'} variant={'outlined'}>
          <Typography>{error.message}</Typography>
          <Box mb={1} mt={2}>
            <pre>{error.stack}</pre>
          </Box>
        </Alert>
        {error.cause && (
          <Box mt={2}>
            <Box mt={2}>
              <Typography>Caused by:</Typography>
            </Box>
            <Box mt={2} ml={5}>
              <ErrorComponent error={error.cause} />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

interface State {
  error?: Error;
}

interface Props extends PropsWithChildren {}

export default class AdminCrmErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return {error};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    return this.state.error ? (
      <Box>
        <Box mb={5}>
          <Typography variant={'h3'}>An unexpected error occurred</Typography>
        </Box>
        <Box>
          <ErrorComponent error={this.state.error} />
        </Box>
      </Box>
    ) : (
      this.props.children
    );
  }
}
