export interface FormRole {
  role_id: string;
  role_name: string;
  priority: number;
  disabled?: boolean;
}

export function compareByPriority<T extends FormRole>(a: T, b: T) {
  return (a.priority ?? 999) - (b.priority ?? 999);
}
