import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Grid} from '@mui/material';
import {FormBuilder} from '../../../../../form/FormBuilder';
import {onRejectSubmit} from '../../../../../form/errorHandler';
import corule_update from './schema/corule_update.json';
import {FormSchema} from '../../../../../form/logic/FormSchema';
import {FormState} from '../../../../../form/state/FormState';
import {useNotifications} from '../../hooks/useNodifications';
import {difference} from '../../../../../form/utils';
import {FormCoRule} from '../../model/FormCoRule';
import {useCoRulesContext} from '../../contexts/CoRulesContext';
import {appendRuleTemplates} from './CoRuleTemplates';

interface Props {
  corule: FormCoRule;
}

export default function CoRuleEditorUpdate({corule}: Props) {
  const {t} = useTranslation();
  const [schema, _] = useState<FormSchema>(appendRuleTemplates(corule_update as FormSchema));
  const {sendSuccess} = useNotifications();
  const {handlePatch, handleDelete, handleExport, handleCopy, locked} = useCoRulesContext();

  function onSubmit(formData: any, state: FormState) {
    delete formData['rule_templates'];
    handlePatch(corule.corule_id, difference(formData, corule)).then((data) => sendSuccess(`ConditionalRule updated`));
  }

  function onDelete() {
    const ok = window.confirm('Are you sure?');
    if (!ok) return;
    handleDelete(corule.corule_id).then(() => sendSuccess(`ConditionalRule deleted`));
  }

  function onExport() {
    handleExport(corule.corule_id).then(() => sendSuccess(`Export completed`));
  }

  function onCopy() {
    handleCopy(corule.corule_id).then(() => sendSuccess(`Copy completed`));
  }

  return (
    <Grid container spacing={1} rowSpacing={5}>
      <Grid item xs={12} style={{resize: 'vertical'}}>
        <FormBuilder onRejectSubmit={onRejectSubmit} formSchema={schema} initialValues={corule} onSubmit={onSubmit}>
          <Button disabled={locked} type={'submit'}>
            {t('shared.update')}
          </Button>
          <Button disabled={locked} onClick={onDelete} color={'error'}>
            {t('shared.delete')}
          </Button>
          {/*
          <Button disabled={locked} onClick={onExport} color={'secondary'}>
            {t('shared.export')}
          </Button>
          <Button disabled={locked} onClick={onCopy} color={'secondary'}>
            {t('shared.copy')}
          </Button>
          */}
        </FormBuilder>
      </Grid>
    </Grid>
  );
}
