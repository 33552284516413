import React, {useState} from 'react';
import ListLayoutComponent from './shared/ListLayoutComponent';
import SingleFeatureComponent from './SingleFeatureComponent';
import FeatureEditorCreate from './editors/FeatureEditorCreate';
import {FeaturesProvider, useFeaturesContext} from '../contexts/FeaturesContext';
import {Grid, TextField} from '@mui/material';
import ImportButton from './ImportButton';
import ClearFormCacheButton from './ClearFormCacheButton';

function InternalFeaturesComponent() {
  const {features, loading} = useFeaturesContext();
  const editor = <FeatureEditorCreate partial={{}} />;
  const [search, setSearch] = useState('');
  const children = features
    ?.filter((f) => f.name.toLocaleLowerCase().includes(search))
    ?.map((feature) => <SingleFeatureComponent feature={feature} key={feature.feature_id} />);
  return (
    <>
      <Grid container spacing={2} sx={{paddingLeft: 10, marginBottom: 2}}>
        <Grid item>
          <TextField
            placeholder={'search...'}
            value={search}
            onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())}
          ></TextField>
        </Grid>
        <Grid item>
          <ImportButton />
        </Grid>
        <Grid item>
          <ClearFormCacheButton />
        </Grid>
      </Grid>
      <ListLayoutComponent title={'Features'} children={children} editor={editor} loading={loading} />
    </>
  );
}

export default function ListFeatureComponent() {
  return (
    <FeaturesProvider>
      <InternalFeaturesComponent />
    </FeaturesProvider>
  );
}
