import React, {useCallback, useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import {useFetch} from '../../../system-settings/form-editor/hooks/useFetch';
import {CronJob, sortCronJobFunc, validateCronJob} from './model';
import CronJobEditor from './editors/CronJobEditor';
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useConfig} from '../../../../context/ConfigContext';

function toSx(cj: CronJob, theme: Theme): SystemStyleObject<Theme> {
  switch (cj.namespace) {
    case 'development':
      return {backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText};
    case 'testing':
      return {backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText};
    case 'staging':
      return {backgroundColor: theme.palette.warning.main, color: theme.palette.warning.contrastText};
    case 'production':
      return {backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText};
    default:
      return {};
  }
}

interface Props {}

export default function CronJobsComponent(props: Props) {
  const {JOB_API_BASE_URL} = useConfig();
  const [selectedCronJob, setSelectedCronJob] = useState<CronJob | null>(null);
  const [cronJobs, setCronJobs] = useState<CronJob[] | null>(null);
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const {send} = useFetch();

  const load = useCallback(
    (namespace?: string) => {
      setCreateNew(false);
      if (namespace) {
        setCronJobs((prev) => (prev ? prev.filter((cj) => cj.namespace !== namespace) : null));
        send('GET', `${JOB_API_BASE_URL}/cronjobs?namespace=${namespace}`).then((data: CronJob[]) =>
          setCronJobs((prev) => (prev ? [...prev, ...data] : data))
        );
      } else {
        setCronJobs(null);
        send('GET', `${JOB_API_BASE_URL}/cronjobs`).then((data: CronJob[]) => setCronJobs(data));
      }
    },
    [send]
  );

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (selectedCronJob) {
      setError(validateCronJob(selectedCronJob));
    }
  }, [selectedCronJob]);

  useEffect(() => {
    setSelectedCronJob((prev) => cronJobs?.find((cj) => cj.cronjob_name === prev?.cronjob_name) ?? null);
  }, [cronJobs]);

  function onCreateNew() {
    setCreateNew(true);
    setSelectedCronJob(null);
  }

  function refreshList(namespace: string, name: string) {
    load(namespace);
  }

  return (
    <>
      <Grid container spacing={2} xs={12}>
        <Grid item xs={12} lg={4} key={'cronjob-list'}>
          <Grid container spacing={2} sx={{marginBottom: 2}}>
            <Grid item>
              <Button onClick={() => load()} color={'secondary'}>
                Reload
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={onCreateNew}>New</Button>
            </Grid>
          </Grid>
          {cronJobs === null ? (
            <CircularProgress sx={{margin: 2, marginLeft: 2}} />
          ) : (
            <>
              {cronJobs.length == 0 ? (
                <Typography>No results</Typography>
              ) : (
                <Paper sx={{maxHeight: '760px', overflow: 'auto'}}>
                  <List>
                    {cronJobs.sort(sortCronJobFunc).map((cj) => (
                      <ListItem disablePadding key={`cronjob-${cj.namespace}-${cj.cronjob_name}`}>
                        <ListItemButton
                          selected={selectedCronJob == cj}
                          onClick={() => setSelectedCronJob((prev) => (prev === cj ? null : cj))}
                        >
                          <ListItemText>
                            <Chip label={cj.namespace} sx={(theme) => toSx(cj, theme)} /> {cj.cronjob_name}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} lg={8} key={'cronjob-editor'}>
          {error ? (
            <Alert severity={'warning'}>
              This job cannot be visualized: <b>{error}</b>
            </Alert>
          ) : selectedCronJob || createNew ? (
            <CronJobEditor cronjob={selectedCronJob} onCreate={refreshList} onDelete={refreshList}></CronJobEditor>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
