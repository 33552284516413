import {Navigate, UNSAFE_NavigationContext as NavigationContext} from 'react-router-dom';
import DashboardPage from './app/pages/dashboard/DashboardPage';
import React, {useContext, useEffect} from 'react';
import {History, Transition} from 'history';
import NotFound from './app/pages/notFound/NotFound';
import LoginPage from './app/pages/login/LoginPage';
import Layout from './app/components/Layout';
import LeadListPage from './app/pages/lead/LeadListPage';
import UserListPage from './app/pages/user/UserListPage';
import UserPage from './app/pages/user/UserPage';
import CampaignListPage from './app/pages/campaign/CampaignListPage';
import CampaignPage from './app/pages/campaign/CampaignPage';
import TaskListPage from './app/pages/task/TaskListPage';
import TeamListPage from './app/pages/team/TeamListPage';
import DemoPage from './app/pages/demo/DemoPage';
import GeneralSettingPage from './app/pages/settings/GeneralSettingPage';
import TaskPage from './app/pages/task/TaskPage';
import TeamPage from './app/pages/team/TeamPage';
import UserProfilePage from './app/pages/profile/UserProfilePage';
import SmsLogPage from './app/pages/smsLog/SmsLogPage';
import SmsPortalPage from './app/pages/smsPortal/SmsPortalPage';
import QueryVisualisationPage from './app/pages/query/QueryVisualization';
import {FeatureName, Path} from './paths';
import LeadPage from './app/pages/lead/LeadPage';
import MassiveUpdatePage from './app/pages/massiveUpdate/MassiveUpdatePage';
import CrdSearchPage from './app/pages/cdr/CrdSearchPage';
import CampaignViewPage from './app/pages/campaign/CampaignViewPage';
import SmsLogListPage from './app/pages/smsLog/SmsLogListPage';
import CallTranscriptionsPage from './app/pages/callTranscriptions/CallTranscriptionsPage';
import AutoActionPage from './app/pages/auto-actions/AutoActionPage';
import AutoActionListPage from './app/pages/auto-actions/AutoActionListPage';
import CallScriptManagerPage from './app/pages/call-script/CallScriptManagerPage';
import CallScriptManagerListPage from './app/pages/call-script/CallScriptManagerListPage';
import QueryBuilderListPage from './app/pages/query/QueryBuilderListPage';
import QueryBuilder from './app/pages/query/QueryBuilder';
import ReportingAnalyticsPage from './app/pages/reporting-analytics/ReportingAnalyticsPage';
import {Auth0User, Auth0UserPermission, hasUserPermission} from './app/model/Auth0User';
import CallScriptManagerConditionsPage from './app/pages/call-script/CallScriptManagerConditionsPage';
import CreativeAssistantPage from './app/pages/creativeAssistant/CreativeAssistantPage';
import OperationListPage from './app/pages/operation/OperationListPage';
import OperationPage from './app/pages/operation/OperationPage';
import LocalPresenceDidsListPage from './app/pages/local-presence-did/LocalPresenceDidsListPage';
import LocalPresenceDidsPage from './app/pages/local-presence-did/LocalPresenceDidsPage';
import PaymentClearancePage from './app/pages/payment-clearence/PaymentClearancePage';
import SystemSettingsRootPage from './app/pages/system-settings/SystemSettingsRootPage';
import SystemSettingsFormEditorPage from './app/pages/system-settings/form-editor/SystemSettingsFormEditorPage';
import SystemSettingsUserPermissionsPage from './app/pages/system-settings/userPermissions/SystemSettingsUserPermissionsPage';
import SystemSettingsFrontendConfigPage from './app/pages/system-settings/frontend-config/SystemSettingsFrontendConfigPage';
import SystemSettingsCachePage from './app/pages/system-settings/cache/SystemSettingsCachePage';
import CachePage from './app/pages/admincrm/cache/CachePage';
import SpannerPage from './app/pages/admincrm/spanner/SpannerPage';
import SupportedOrgsPage from './app/pages/admincrm/supported-orgs/SupportedOrgsPage';
import {Config} from './app/Config';
import SystemSettingsFeaturePermissionPage from './app/pages/system-settings/permissions/SystemSettingsFeaturePermissionPage';
import CronJobsPage from './app/pages/admincrm/cronjobs/CronJobsPage';

const routes = (isAuthenticated: boolean, user: Auth0User | undefined, config: Config) => {
  const isAuthenticatedElement = isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />;
  const canViewSystemSettings = hasUserPermission(user, Auth0UserPermission.VIEW_SYSTEM_SETTINGS);
  const canViewSystemSettingsElement = canViewSystemSettings ? (
    <Layout />
  ) : isAuthenticated ? (
    <Navigate to={'/404'} />
  ) : (
    <Navigate to={'/' + Path.LOGIN} />
  );
  const isAdminCrm = config.ORG_NAME === 'admincrm';
  const isAdminCrmElement = isAdminCrm ? (
    <Layout />
  ) : isAuthenticated ? (
    <Navigate to={'/404'} />
  ) : (
    <Navigate to={'/' + Path.LOGIN} />
  );
  return [
    {
      path: '/',
      element: <Layout hideSideNav={true} />,
      children: [
        {path: '', element: <Navigate to={'/' + Path.DASHBOARD} />},
        {
          path: '/' + Path.LOGIN,
          element: !isAuthenticated ? <LoginPage /> : <Navigate to={'/' + Path.DASHBOARD} />,
        },
        {path: '404', element: <NotFound />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.DASHBOARD,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <DashboardPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.LEADS,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <LeadListPage />},
        {path: ':id', element: <LeadPage />},
        {path: 'create', element: <LeadPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.DEMO,
      element: <Layout />,
      children: [
        {path: '', element: <DemoPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.USERS,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <UserListPage />},
        {path: 'create', element: <UserPage />},
        {path: ':id', element: <UserPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.TASKS,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <TaskListPage />},
        {path: 'create', element: <TaskPage />},
        {path: ':id', element: <TaskPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.CAMPAIGNS,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <CampaignListPage />},
        {path: 'create', element: <CampaignPage />},
        {path: ':id', element: <CampaignPage />},
        {path: 'view/:campaignId', element: <CampaignViewPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.TEAMS,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <TeamListPage />},
        {path: 'create', element: <TeamPage />},
        {path: ':id', element: <TeamPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.QUERIES,
      element: isAuthenticatedElement,
      children: [
        {path: 'visualization', element: <QueryVisualisationPage />},
        {path: '*', element: <Navigate to="/404" />},
        {path: ':featureId', element: <QueryBuilderListPage />},
        {path: ':featureId/create', element: <QueryBuilder />},
        {path: ':featureId/:id', element: <QueryBuilder />},
      ],
    },
    {
      path: FeatureName.PROFILE,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <UserProfilePage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.SETTINGS,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <GeneralSettingPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.SMS_LOG,
      element: isAuthenticatedElement,
      children: [
        {path: 'create', element: <SmsLogPage />},
        {path: '', element: <SmsLogListPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.SMS_PORTAL,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <SmsPortalPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.MASSIVE_UPDATE,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <MassiveUpdatePage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.CDR,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <CrdSearchPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.RECORDING_ANALYTICS,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <CallTranscriptionsPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.AUTO_ACTIONS,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <AutoActionListPage />},
        {path: 'create', element: <AutoActionPage />},
        {path: ':id', element: <AutoActionPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.USER_GUIDE,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <CallScriptManagerListPage />},
        {path: 'create', element: <CallScriptManagerPage />},
        {path: `:id`, element: <CallScriptManagerPage />},
        {path: `conditions/:id`, element: <CallScriptManagerConditionsPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.CAMPAIGNS,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <CampaignListPage />},
        {path: 'create', element: <CampaignPage />},
        {path: ':id', element: <CampaignPage />},
        {path: 'view/:campaignId', element: <CampaignViewPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.TEAMS,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <TeamListPage />},
        {path: 'create', element: <TeamPage />},
        {path: ':id', element: <TeamPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.QUERIES,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: 'visualization', element: <QueryVisualisationPage />},
        {path: '*', element: <Navigate to="/404" />},
        {path: ':featureId', element: <QueryBuilderListPage />},
        {path: ':featureId/create', element: <QueryBuilder />},
        {path: ':featureId/:id', element: <QueryBuilder />},
      ],
    },
    {
      path: FeatureName.PROFILE,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <UserProfilePage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.SETTINGS,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <GeneralSettingPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.SMS_LOG,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: 'create', element: <SmsLogPage />},
        {path: '', element: <SmsLogListPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.SMS_PORTAL,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <SmsPortalPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.MASSIVE_UPDATE,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <MassiveUpdatePage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.CDR,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <CrdSearchPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.RECORDING_ANALYTICS,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <CallTranscriptionsPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.AUTO_ACTIONS,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <AutoActionListPage />},
        {path: 'create', element: <AutoActionPage />},
        {path: ':id', element: <AutoActionPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.USER_GUIDE,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <CallScriptManagerListPage />},
        {path: 'create', element: <CallScriptManagerPage />},
        {path: `:id`, element: <CallScriptManagerPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.REPORTING_ANALYTICS,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <ReportingAnalyticsPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.CREATIVE_ASSISTANT,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <CreativeAssistantPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: FeatureName.OPERATIONS,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <OperationListPage />},
        {path: 'create', element: <OperationPage />},
        {path: ':id', element: <OperationPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.LOCAL_PRESENCE_DIDS,
      element: isAuthenticated ? <Layout /> : <Navigate to={'/' + Path.LOGIN} />,
      children: [
        {path: '', element: <LocalPresenceDidsListPage />},
        {path: 'create', element: <LocalPresenceDidsPage />},
        {path: ':id', element: <LocalPresenceDidsPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.SYS_SETTINGS_ROOT,
      element: canViewSystemSettingsElement,
      children: [
        {path: '', element: <SystemSettingsRootPage />},
        {path: Path.SYS_SETTINGS_FORM_EDITOR, element: <SystemSettingsFormEditorPage />},
        {path: Path.SYS_SETTINGS_ROLE_PERMISSIONS, element: <SystemSettingsFeaturePermissionPage />},
        {path: Path.SYS_SETTINGS_USER_PERMISSIONS, element: <SystemSettingsUserPermissionsPage />},
        {path: Path.SYS_SETTINGS_FRONTEND_CONFIG, element: <SystemSettingsFrontendConfigPage />},
        {path: Path.SYS_SETTINGS_CACHE, element: <SystemSettingsCachePage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: 'admincrm',
      element: isAdminCrmElement,
      children: [
        {path: 'cache', element: <CachePage />},
        {path: 'spanner', element: <SpannerPage />},
        {path: 'supported-orgs', element: <SupportedOrgsPage />},
        {path: 'cronjobs', element: <CronJobsPage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: Path.PAYMENT_CLEARANCE,
      element: isAuthenticatedElement,
      children: [
        {path: '', element: <PaymentClearancePage />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
  ];
};

type ExtendNavigator = Navigator & Pick<History, 'block'>;

export function useNavigationBlocker(blocker: (tx: Transition) => void, blockTransition: Boolean) {
  const {navigator} = useContext(NavigationContext);
  useEffect(() => {
    if (!blockTransition) return;
    const unblock = (navigator as unknown as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, blockTransition]);
}

export default routes;
